import React, {useState, useEffect } from "react";
import { Provider } from "react-redux";
import GlobalStyles from "@iso/assets/styles/globalStyle";
import { store } from "./redux/store";
import Boot from "./redux/boot";
import Routes from "./router";
import AppProvider from "./AppProvider";
import axios from "axios";
import useUser from "./useUser";
import { PermifyProvider } from "@permify/react-role";
import { HasAccess } from "@permify/react-role";
export const AuthContext = React.createContext();

const App = () => {
    const {user} = useUser()
    axios.defaults.baseURL = 'https://k.adanto.jellydev.pl';
  //console.log(user.permissions.users)
    return (<Provider store={store}>
        <AppProvider>
            <>
                <GlobalStyles/>
                <Routes/>
               
                 
            
            </>
        </AppProvider>
    </Provider>)

};
Boot()
  .then(() => App())
  .catch((error) => console.error(error));

export default App;
